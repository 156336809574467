import React, { Component } from 'react';
import './style.css';

class About extends Component {
  render() {
    return (
      <>
        <h1>About</h1>
        <p>About</p>
      </>
    )
  }
}

export default About;