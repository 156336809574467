import React, { Component } from 'react';
import './style.css';

class Contact extends Component {
  render() {
    return (
      <>
        <h1>Contact</h1>
        <p>Contact</p>
      </>
    )
  }
}

export default Contact;